.container {
  display: flex;
  height: calc(100vh - 170px);
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  .gameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    .shuffledContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: 200px;
      flex-wrap: wrap;
      justify-content: center;

      div {
        cursor: pointer;
        padding: 6px 16px;
        font-size: 18px;
        font-weight: 700;
        color: #1a1a1a;
        border: 2px solid #a5a6ff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .resultContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: 200px;
      border-bottom: 3px solid #4137ee;
      padding: 5px;
      flex-wrap: wrap;
      justify-content: center;

      div {
        cursor: pointer;
        padding: 6px 16px;
        font-size: 18px;
        font-weight: 700;
        color: #1a1a1a;
        border: 2px solid #a5a6ff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    height: calc(100vh - 85px);
  }
  .buttonsContainer {
    gap: 30px !important;
  }
}
