.container {
  display: flex;
  width: 100%;
  height: calc(100vh - 170px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  .lessonListWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    div {
      border: 2px solid #4137ee;
      border-radius: 20px;
      min-height: 50px;
      display: flex;
      align-items: center;
      min-width: 300px;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
      color: #4137ee;
    }
  }
  .navContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    .navImgWrapper {
      display: flex;
      cursor: pointer;
      align-items: center;
      width: 220px;
      height: 60px;
      border-radius: 30px;
      position: relative;
      .navImg {
        position: absolute;
        right: 10px;
        top: 15px;
        width: 33px;
      }
      .navImgTitle {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        max-width: 60%;
        text-align: center;
      }
    }
  }
}
