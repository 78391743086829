.loginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 300px;
  padding: 24px 16px;
  border: 1.5px solid rgb(229, 229, 229);
  border-radius: 8px;
  outline: none;
  .loginHeader {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    button,
    input,
    div {
      border-radius: 20px;
    }
  }
}
