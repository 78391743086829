.modalContainer {
  margin: 0 auto;
  max-width: 280px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
  text-align: center;
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  button {
    background-color: #4137ee;
    font-weight: 700;
    font-size: 16px;
    width: fit-content;
    border-radius: 30px;
    color: #ffffff;
    height: 40px;
    text-transform: none;
    min-width: 200px;
    box-shadow: none;
  }

  button:hover {
    background-color: #4137ee;
    color: #ffffff;
    box-shadow: none;
  }

  button:disabled {
    background-color: #e3e3ff;
    color: #4137ee;
  }
}

.editClassContainer {
  display: flex;
  height: calc(100vh - 170px);
  width: 80%;
  margin: 0 auto;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  textarea {
    width: 500px;
  }
  .classTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .classTitleEditWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      button {
        width: auto;
        min-width: auto;
        height: 40px;
      }
    }
  }
  .studentsListTitle {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      margin: 0;
    }
  }
  .studentsListWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    table {
      border-collapse: collapse;
    }
    button {
      width: auto;
      min-width: auto;
      height: 40px;
    }
    thead,
    th,
    tr,
    td {
      border: 3px solid #4137ee;
      height: 62px;
    }
    tr,
    td {
      font-size: 24px;
      font-weight: 500;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }
    .buttonsWrapper {
      min-width: 160px;
      display: flex;
      justify-content: center;
      max-width: 160px;
      align-items: center;
      border: none;
      gap: 10px;
    }
    .studentNameWrapper {
      width: 100%;
      padding: 0 16px;
    }
    .studentIndexWrapper {
      max-width: 40px;
      min-width: 40px;
      text-align: center;
      .checkmark {
        width: 20px !important;
        min-width: 0 !important;
        height: 20px;
        cursor: pointer;
      }
    }
    .dateWrapper {
      text-align: center;
      padding: 0 16px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    height: calc(100vh - 85px);
  }
  .editClassContainer {
    height: calc(100% - 85px);
    width: 90%;
    textarea {
      width: 320px;
    }
  }
  .studentsListWrapper {
    button {
      width: 25px !important;
      height: 25px !important;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .buttonsWrapper {
      min-width: 80px !important;
      max-width: 80px !important;
    }
  }
}
