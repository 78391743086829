.container {
  display: flex;
  height: calc(100vh - 170px);
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  .gameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    .gameBody {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      h2 {
        font-size: 24px;
        font-weight: 700;
        color: #ff6668;
      }
      .stopRecordBtn {
        background-color: crimson;
      }

      .startRecordBtn {
      }
    }
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    height: calc(100vh - 85px);
  }
}
