.landingContainer {
  display: flex;
  height: calc(100vh - 170px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
}

@media only screen and (max-width: 768px) {
  .landingContainer {
    height: calc(100vh - 85px);
  }
}
