.header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  .headerTop {
    width: 100%;
    height: 170px;
    border-radius: 0px 0px 30px 0px;
    background: #a5a6ff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      gap: 10px;
      .logoText {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 700;
          font-size: 40px;
          color: #ffffff;
          line-height: 39px;
        }
      }
    }

    .smallLogo {
      img {
        width: 46px;
      }
      .logoText {
        display: none;
      }
    }

    .headerControls {
      display: flex;
      gap: 20px;
      margin-right: 20px;
    }

    .userPreview {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 20px;

      span {
        font-size: 16px;
        font-weight: 500;
        color: rgb(75, 75, 75);
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        margin-left: 0;
      }
    }
  }
  .headerTop::before {
    content: "";
    position: absolute;

    background-color: transparent;
    bottom: -60px;
    left: 0;
    height: 60px;
    width: 30px;
    border-top-left-radius: 30px;
    box-shadow: 0 -30px 0 0 #a5a6ff;
  }

  .logoutWrapper {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 20px);
    right: 24px;
    button {
      border: none;
      min-width: 100px;
    }
  }
}
