.container {
  display: flex;
  height: calc(100vh - 170px);
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  .gameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    .keyword {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      font-size: 24px;
      font-weight: 700;
      color: #ff6668;
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      img {
        height: 33px;
        width: 33px;
      }
    }

    .imgContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      grid-row-gap: 1em;
      grid-column-gap: 1em;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 250px;
        cursor: pointer;
        margin: 3px;
      }
      img {
        width: 240px;
        aspect-ratio: 1;
        border-radius: 20px;
      }
    }

    .correctImg {
      margin: 0 !important;
      border: 3px solid #4137ee;
      border-radius: 25px;
    }

    .wrongImg {
      border: 3px solid #ff6668;
      border-radius: 25px;
      margin: 0 !important;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

@media only screen and (max-height: 800px) {
  .container {
    .gameContainer {
      gap: 15px;
    }
    .imgContainer {
      div {
        width: 150px !important;
        height: 150px !important;
      }
      img {
        width: 140px !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 95%;
    height: calc(100vh - 85px);
    .imgContainer {
      div {
        width: 150px !important;
        height: 150px !important;
      }
      img {
        width: 140px !important;
      }
    }
  }
}
