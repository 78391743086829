.App {
  display: block;
  min-width: 100%;
  min-height: var(--100vh, 100vh);

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button {
    background-color: #4137ee;
    font-weight: 700;
    font-size: 16px;
    width: fit-content;
    border-radius: 30px;
    color: #ffffff;
    height: 40px;
    text-transform: none;
    min-width: 200px;
    box-shadow: none;
  }

  button:hover {
    background-color: #4137ee;
    color: #ffffff;
    box-shadow: none;
  }

  button:disabled {
    background-color: #e3e3ff;
    color: #4137ee;
  }

  textarea,
  input {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    resize: none;
    border: 2px solid #4137ee;
    border-radius: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  textarea {
    min-height: 200px;
    min-width: 200px;
  }
  input {
    min-height: 30px;
    min-width: 200px;
  }
  textarea:focus,
  input:focus {
    outline: none !important;
  }
}
